<template>
  <div class="ResponsiveContentBoard">
    <div class="mainTitle textAverage">Lista de Usuários</div>
    <div class="inputContainer">
      <input class="inputSearch textSmall" type="text" placeholder="Pesquisar" v-model="userSearch">
      <img class="searchIcon" src="@/assets/icons/outros/search2.svg" />
    </div>
    <div class="tableArea">
      <div class="tableInternalArea">
        <table class="tableNormal minSize textSmall">
            <tr>
              <th class="ResponsiveTableHead">Nome</th>
              <th class="ResponsiveTableHead">E-mail</th>
              <th class="ResponsiveTableHead">Permissão</th>
              <th class="ResponsiveTableHead">Status</th>
              <th class="ResponsiveTableHead" style="width: 160px"></th>
            </tr>
            <tr v-for="(user, index) in userListFilter" :key="index">
              <td>{{user.name}}</td>
              <td>{{user.email}}</td>
              <td v-if="user.accessLevel == accessLevel.DIRETOR">{{"Diretor"}}</td>
              <td v-else-if="user.accessLevel == accessLevel.DESENVOLVIMENTO">{{"Desenvolvimento"}}</td>
              <td v-else-if="user.accessLevel == accessLevel.SUPORTE">{{"Suporte"}}</td>
              <td v-else-if="user.accessLevel == accessLevel.PRODUCAO">{{"Produção"}}</td>
              <td v-else-if="user.accessLevel == accessLevel.VENDAS">{{"Vendas"}}</td>
              <td v-else-if="user.accessLevel == accessLevel.INTEGRADOR">{{"Integrador"}}</td>
              <td v-else-if="user.accessLevel == accessLevel.CLIENTE">{{"Cliente"}}</td>
              <td v-else>{{"Monitoramento"}}</td>
              <td v-if="user.realmId != null">{{"Confirmado"}}</td>
              <td v-else>{{"Pendente"}}</td>
              <td class="ResponsiveCellConfig">
                <div @click="editUser(user)" class="btnIcon btnSmallSize btnTypeSave">
                  <img class="smallIconBtn" src="@/assets/icons/Setting/edit.svg" />
                </div>

                <div v-if="getCurrentUserId() != user.realmId">
                  <div v-if="user.blockedAt != null" @click="unlockUser(user)" class="btnIcon btnSmallSize btnTypeSave">
                    <img class="smallIconBtn" src="@/assets/icons/Setting/padlock.svg" />
                  </div>
                  <div v-else @click="lockUser(user)" class="btnIcon btnSmallSize btnTypeSave">
                    <img class="smallIconBtn" src="@/assets/icons/Setting/padunlock.svg" />
                  </div>
                </div>
                <div v-else>
                  <div v-if="user.blockedAt != null" class="btnIcon btnSmallSize btnTypeDisabled" disable="true">
                    <img class="smallIconBtn" src="@/assets/icons/Setting/padlock.svg" />
                  </div>
                  <div v-else class="btnIcon btnSmallSize btnTypeDisabled" disable="true">
                    <img class="smallIconBtn" src="@/assets/icons/Setting/padunlock.svg" />
                  </div>
                </div>

                <div v-if="getCurrentUserId() != user.realmId">
                  <div @click="deleteUser(user)" class="btnIcon btnSmallSize btnTypeDelete">
                    <img class="smallIconBtn" src="@/assets/icons/Setting/trash.svg" />
                  </div>
                </div>
                <div v-else>
                  <div class="btnIcon btnSmallSize btnTypeDelete btnTypeDisabled" disable="true">
                    <img class="smallIconBtn" src="@/assets/icons/Setting/trash.svg" />
                  </div>
                </div>
              </td>
            </tr>
        </table>
      </div>
    </div>
    <div class="ResponsiveBtnConfigArea">
      <router-link class="btn btnNormalSize btnTypeSave" style="margin-left: 16px" :to="{ name:'RegisterUser', params: {user:null, usersList:this.usersList}}">Novo</router-link>
      <button @click="comeBack()" class="btn btnNormalSize btnTypeCancel">Voltar</button>
    </div>
    <PopUp msg="Carregando lista de usuários..." :show="load" type="Loader"/>
    <PopUp :msg="faultMsg" :show="faultOnLoadUsers" type="Fault" :onOk="popUpOk"/>
  </div>
</template>

<script>
import PopUp from '@/views/PopUp/PopUp.vue'
import UserDAO from '@/sys/Control/User/UserDAO'
import BackEnd from '@/sys/BackEnd/BanckEnd'
import { AccessLevel } from '@/sys/Model/Permissions/AccessLevel'

export default {
  components: {PopUp},
  methods: {
    getCurrentUserId() {
      return BackEnd.getCurrentUserId();
    },
    getCurrentUserAccess(){
      return BackEnd.getCurrentUserAccess();
    },
    editUser(user) {
      this.$router.push({name:'RegisterUser', params: {user: user, usersList:this.usersList}});
    },
    lockUser(user) {
      user.blockedAt = new Date();
      UserDAO.updateUser(user, true);
    },
    unlockUser(user) {
      user.blockedAt = null;
      UserDAO.updateUser(user, true);
    },
    deleteUser(user) {
      this.usersList = this.usersList.filter( currentUser => { return currentUser.email != user.email})
      UserDAO.deleteUser(user);
    },
    comeBack() {
      this.$router.push({path: '/Setting/ChooseSetting'})
    },
    popUpOk: function(){
      this.faultOnLoadUsers=false;
    }
  },
  computed:{
    userListFilter() {
      if(this.usersList != null) {
        return this.usersList.filter(user => {
          return user.name.toLowerCase().includes(this.userSearch.toLowerCase()) ||
            user.email.toLowerCase().includes(this.userSearch.toLowerCase())
        })
      }
    }
  },
  data: function() {
    return {
      load: true,
      usersList: null,
      userSearch: '',
      faultOnLoadUsers: false,
      faultMsg: null,
      accessLevel: AccessLevel
    }
  },
  watch: {
    usersList() {
      if(this.usersList != null) {
        this.load = false;
      }
    }
  },
  async created() {
    this.usersList = await UserDAO.readAllActiveUsers(this.getCurrentUserAccess()).catch(e=>{
      this.load = false;
      this.faultMsg=e;
      this.faultOnLoadUsers=true;
    });
  }
};
</script>

<style scoped>
.ResponsiveContentBoard {
  flex-flow: column nowrap;
}
.tableArea {
  flex-grow: 1;/* Define que a tabela é o unico item que pode mudar de tamanho */
  position: relative;
}
.tableInternalArea {
  position: absolute;
  bottom: 0px;
  top: 0px;
  left: 10px;/*Usado como paddin da tabela */
  right: 10px;/*Usado como paddin da tabela */
  background-color: #1e2227;
  overflow: auto;
}
@media(max-width: 825px){
  .tableInternalArea {
    top: 10px;
    left: 5px;/*Usado como paddin da tabela */
    right: 5px;/*Usado como paddin da tabela */
  }
}
</style>